import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import {getUsers} from '@/helpers/api/users.js'
import {listEvaluations} from '@/helpers/api/evaluations.js'

const token = localStorage.getItem('userToken')

const state = {
  userData: null,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isAuthenticated: token,
  loadingClientList: false,
  clientList: null,
  newUserModal: false,
  editUserModal: {status: false, userData: null},
  loadingEvaluatorList: false,
  evaluatorList: null,
  newNetworkUserModal: false,
  editNetworkUserModal: {status: false, userData: null},
  countries: [],
  states: [],
  comunas: [],
  fixedToasts: [],
  evaluationsList: null,
  loadingEvaluationsList: false,
  newEvaluationModal: false,
	dashboardData: null
}

const mutations = {
  setUserData (state, user) {
		console.log(user)
		const data = {
			...user.datos,
			pais: user.pais.name
		}
		console.log(data)
    state.userData = data
  },
  setIsAuthenticated (state, status) {
    state.isAuthenticated = status
  },
  setLoadingClientList (state, status) {
    state.loadingClientList = status
  },
  setClientList (state, list){
    state.clientList = list
  },
  setNewUserModal(state, status){
    state.newUserModal = status
  },
  setEditUserModal(state, data){
    state.editUserModal = data
  },
  setLoadingEvaluatorList (state, status) {
    state.loadingEvaluatorList = status
  },
  setEvaluatorList (state, list){
    state.evaluatorList = list
  },
  setNewNetworkUserModal(state, status){
    state.newNetworkUserModal = status
  },
  setEditNetworkUserModal(state, data){
    state.editNetworkUserModal = data
  },
  setCountries(state, countries){
    state.countries = countries
  },
  setStates(state, states){
    state.states = states
  },
  setComunas(state, comunas){
    state.comunas = comunas
  },
  setEvaluations(state, evaluations){
    state.evaluationsList = evaluations
  },
  setLoadingEvaluationsList(state, status){
    state.loadingEvaluationsList = status
  },
  setNewEvaluationModal(state, status){
    state.newEvaluationModal = status
  },
  addToast(state, newToast){
    state.fixedToasts.push(newToast)
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setDashboardData(state, data){
		state.dashboardData = data
	}
}

const actions = {
  async getUsersList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      context.commit('setLoadingClientList', true)

      const data = {
        type: query.type,
        parent_user_id: context.state.userData.id_usuario
      }
      const users = await getUsers(data)
      const action = query.type == 1 ? 'setEvaluatorList':'setClientList'
      const actionLoad = query.type == 1 ? 'setLoadingEvaluatorList':'setLoadingClientList'
      context.commit(action, users.lista)
      context.commit(actionLoad, false)
    }
  },
  async getEvaluationList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      context.commit('setLoadingEvaluationsList', true)

      const data = {
        type: 0,
        id_usuario: context.state.userData.id_usuario
      }
      const evaluations = await listEvaluations(data)
      context.commit('setEvaluations', evaluations.datos)
      context.commit('setLoadingEvaluationsList', false)
    }
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})