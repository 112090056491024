import {endpoints} from './endpoints'
import request from '@/helpers/request'
import store from '@/store.js'

export function login(email, pass){
	return new Promise((resolve, reject) => {
		const data = {
	    email: email,
	    password: pass
	  }
    request({
      url: endpoints.login,
      method: 'post',
      data
    })
		  .then(async function (response) {
		  	setUserToken(response.token)
		  	const user = await getAuth()
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getDetails(userID){
	return new Promise((resolve, reject) => {
		const data = {
	    id: userID
	  }
    request({
      url: endpoints.userDetails,
      method: 'post',
      data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

function setUserToken(token){
	localStorage.setItem('userToken', token)
  store.commit('setIsAuthenticated', true)
}
function removeUserToken(){
	localStorage.removeItem('userToken')
  store.commit('setIsAuthenticated', false)
}

export function getAuth(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.authUser,
      method: 'get'
    })
		  .then(async function (response) {
        const user = await getUser({id_usuario: response.user.id})
				const dash = await getDash({id_usuario: response.user.id, type: response.user.type})
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.user,
      method: 'get',
      params: data
    })
		  .then(function (response) {
        store.commit('setUserData', response)
		    resolve(response)
		  })
		  .catch(function (error) {
				console.log("ERROR: ", error)
		    reject(error)
		  });
	})
}

export function getDash(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.dashboard,
      method: 'get',
      params: data
    })
		  .then(function (response) {
        store.commit('setDashboardData', response.data)
		    resolve(response)
		  })
		  .catch(function (error) {
				console.log("ERROR: ", error)
		    reject(error)
		  });
	})
}