<template>
  <div>
    <CModal
      :title="$t('newEvaluation')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
      :show.sync="$store.state.newEvaluationModal"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('newEvaluation')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setNewEvaluationModal', false)
          resetModal()
        }" class="text-white"/>
      </template>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol sm="12" md="12">
								<CRow v-show="step == 1">
									<CCol sm="12" md="6">
                  	<h5 class="bg-primary text-white text-center py-2">{{ $t('evaluateData') }} </h5>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{ $t('userToEvaluate') }} </label>
											<div class="col-sm-9">
												<v-select
													v-if="$store.state.clientList"
													:placeholder="$t('selectUserToEvaluate')"
													:options="$store.state.clientList"
													:clearable="false"
													label="nombre"
													required
													:isValid="needValidation ? required:undefined"
													@option:selected="(user) => {
														userSelected(user)
													}"
													v-model="evaluado">
													<template #option="{nombre, apellido}">
														<p class="mb-0">{{nombre}} {{apellido}}</p>
													</template>
												</v-select>
											</div>
										</div>
									</CCol>
									<CCol sm="12" md="6">
                  	<h5 class="bg-primary text-white text-center py-2">{{ $t('evaluationType') }}</h5>
										<CButton :color="selectedType == 1 ? 'primary':'secondary'" block
											@click="selectedType = 1">{{$t('imcMethod')}}</CButton>
										<!-- <CButton :color="selectedType == 2 ? 'primary':'secondary'" block
											@click="selectedType = 2">Método Cinta Métrica</CButton> -->
										<CButton :color="selectedType == 3 ? 'primary':'secondary'" block
											@click="selectedType = 3">{{$t('twoPMethod')}}</CButton>
										<CButton :color="selectedType == 4 ? 'primary':'secondary'" block
											@click="selectedType = 4">{{$t('fourPMethod')}}</CButton>
									</CCol>
								</CRow>
								<CRow v-show="step == 2">
									<CCol sm="12" md="12">
                  	<h5 class="bg-primary text-white text-center py-2">{{$t('entryData')}}</h5>
									</CCol>
									<CCol sm="12" md="6">
										<CInput
											v-model="newEvaluation.estatura"
											:label="$t('height')"
											type="number"
											:placeholder="$t('enterHeight')"
											horizontal
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-model="newEvaluation.peso"
											:label="$t('weight')"
											type="number"
											:placeholder="$t('enterWeight')"
											horizontal
											:isValid="needValidation ? required:undefined"
										/>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{$t('smoker')}} </label>
											<div class="col-sm-9">
												<v-select
													:placeholder="$t('smoker')"
													:options="[{label: $t('yes'), value: 'Si'}, {label: $t('no'), value: 'No'}]"
													:clearable="false"
													required
													:isValid="needValidation ? required:undefined"
													v-model="newEvaluation.fuma"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-3"> {{$t('drinkAlcohol')}} </label>
											<div class="col-sm-9">
												<v-select
													:placeholder="$t('drinkAlcohol')"
													:options="[{label: $t('yes'), value: 'Si'}, {label: $t('no'), value: 'No'}]"
													:clearable="false"
													required
													:isValid="needValidation ? required:undefined"
													v-model="newEvaluation.alcohol"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-6"> {{$t('doesExercise')}} </label>
											<div class="col-sm-6">
												<v-select
													:placeholder="$t('doesExercise')"
													:options="[{label: $t('yes'), value: 'Si'}, {label: $t('no'), value: 'No'}]"
                      		:reduce="data => data.value"
													:clearable="false"
													required
													:isValid="needValidation ? required:undefined"
													v-model="newEvaluation.ejercita"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row">
											<label class="col-form-label col-sm-6"> {{newEvaluation.ejercita == 'No' ? $t('noSinceTrainingQuestion'):$t('sinceTrainingQuestion')}} </label>
											<div class="col-sm-6">
												<v-select
													:placeholder="newEvaluation.ejercita == 'No' ? $t('noSinceTrainingQuestion'):$t('sinceTrainingQuestion')"
													:options="haceEntreno"
													:clearable="false"
													required
                      		:reduce="data => data.value"
													:isValid="needValidation ? required:undefined"
													v-model="newEvaluation.hace"></v-select>
											</div>
										</div>
										<div role="group" class="form-group form-row" v-if="newEvaluation.ejercita == 'Si'">
											<label class="col-form-label col-sm-6"> {{$t('trainingFrequencyQuestion')}} </label>
											<div class="col-sm-6">
												<v-select
													:placeholder="$t('trainingFrequencyQuestion')"
													:options="frecEntreno"
													:clearable="false"
													required
                      		:reduce="data => data.value"
													:isValid="needValidation ? required:undefined"
													v-model="newEvaluation.frecentrena"></v-select>
											</div>
										</div>
									</CCol>
									<CCol sm="12" md="6">
										<CInput
											v-model="newEvaluation.pcintura"
											:label="$t('waistPerimeter')"
											type="number"
											:placeholder="$t('enterWaistPerimeter')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-model="newEvaluation.pcadera"
											:label="$t('hipPerimeter')"
											type="number"
											:placeholder="$t('enterHipPerimeter')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-model="newEvaluation.presion_d"
											:label="$t('diastolic') + ' (' + $t('optional') +')'"
											type="number"
											:placeholder="$t('enterDiastolic')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
										/>
										<CInput
											v-model="newEvaluation.presion_s"
											:label="$t('sistolic') + ' (' + $t('optional') +')'"
											type="number"
											:placeholder="$t('enterSistolic')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
										/>
										<CInput
											v-if="selectedType == 3 || selectedType == 4"
											v-model="newEvaluation.triceps"
											:label="$t('pTriceps')"
											type="number"
											:placeholder="$t('enterPTriceps')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-if="selectedType == 3 || selectedType == 4"
											v-model="newEvaluation.subescapular"
											:label="$t('pSubescapular')"
											type="number"
											:placeholder="$t('enterPSubescapular')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-if="selectedType == 4"
											v-model="newEvaluation.abdominal"
											:label="$t('pAbdominal')"
											type="number"
											:placeholder="$t('enterPAbdominal')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
										<CInput
											v-if="selectedType == 4"
											v-model="newEvaluation.cuadriceps"
											:label="$t('pCuadriceps')"
											type="number"
											:placeholder="$t('enterPCuadriceps')"
											:horizontal="{label: 'col-sm-5', input: 'col-sm-7'}"
											:isValid="needValidation ? required:undefined"
										/>
									</CCol>
								</CRow>
              </CCol>
            </CRow>
						<CRow v-show="step == 2">
							<CCol sm="12" md="12">
								<h5 class="bg-primary text-white text-center py-2">{{$t('clinicalData')}}</h5>
							</CCol>
							<CCol sm="12" md="6">
								<CInput
									v-model="newEvaluation.clinical_data.temperatura"
									:label="$t('temperature')"
									type="number"
									horizontal
									:placeholder="$t('temperature') + ' ºC'"
									:isValid="needValidation ? required:undefined"
								>
								<div slot="description">
									<p class="mb-0 text-right text-danger" v-if="newEvaluation.clinical_data.temperatura >= 37.9">
										<CIcon name="cil-warning" class="mr-2"/> 
										<small>{{$t('feverCaution')}}</small>
									</p>
								</div>
								</CInput>
							</CCol>
							<CCol sm="12" md="6">
                <CRow form class="form-group">
                  <CCol sm="6">
                    {{$t('hasDificulToBreath')}}
                  </CCol>
                  <CInputRadioGroup
                    class="col-sm-6"
                    :options="[{value: true, label: $t('yes')},{value: false, label: $t('no')}]"
										:value="newEvaluation.clinical_data.dif_respirar"
										:checked.sync="newEvaluation.clinical_data.dif_respirar"
                    inline
                  />
                </CRow>
							</CCol>
							<CCol sm="12" md="6">
								<div class="py-3">
									<div class="d-flex justify-content-between px-2 py-1 bg-dark">
										<p class="mb-0 text-white px-2">{{$t('hasECNTtitle')}}</p>
										<p class="mb-0 text-white text-right px-2">{{$t('markIntreatment')}}</p>
									</div>
									<div class="d-flex justify-content-between px-2 py-1"
										:class="optionIndex%2 != 0 ? 'bg-secondary':''"
										v-for="(option, optionIndex) in newEvaluation.clinical_data.ecnt" :key="optionIndex">
										<div class="d-flex">
											<CInputCheckbox
												:label="option.showInput ? '':$t(option.name)"
												:value="option.value"
												:name="optionIndex"
												:checked.sync="option.value"
											/>
											<CInput
												v-if="option.showInput"
												v-model="option.name"
												:placeholder="$t('otherDisease')"
												class="mb-0"
											/>
										</div>
										<CInputCheckbox
											:disabled="!option.value"
											:value="option.tratamiento"
											:name="optionIndex"
											:checked.sync="option.tratamiento"
										/>
									</div>
								</div>
							</CCol>
							<CCol sm="12" md="6">
								<div class="py-3">
									<div class="d-flex justify-content-between px-2 py-1 bg-dark">
										<p class="mb-0 text-white px-2">{{$t('hasAutoinmuneTitle')}}</p>
										<p class="mb-0 text-white text-right px-2">{{$t('markIntreatment')}}</p>
									</div>
									<div class="d-flex justify-content-between px-2 py-1"
										:class="optionIndex%2 != 0 ? 'bg-secondary':''"
										v-for="(option, optionIndex) in newEvaluation.clinical_data.autoinmune" :key="optionIndex">
										<div class="d-flex">
											<CInputCheckbox
												:label="option.showInput ? '':$t(option.name)"
												:value="option.value"
												:name="optionIndex"
												:checked.sync="option.value"
											/>
											<CInput
												v-if="option.showInput"
												v-model="option.name"
												:placeholder="$t('otherDisease')"
												class="mb-0"
											/>
										</div>
										<CInputCheckbox
											:disabled="!option.value"
											:value="option.tratamiento"
											:name="optionIndex"
											:checked.sync="option.tratamiento"
										/>
									</div>
								</div>
							</CCol>
						</CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <div slot="footer" class="d-flex align-items-center justify-end">
        <CBadge v-if="showRequiredAlert" color="warning" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> {{$t('fieldRequiredEv')}}</CBadge>
				<CButton v-if="step > 1" color="secondary" @click="step--" :disabled="loading" class="mr-3"><CIcon name="cil-chevron-left"/> {{$t('back')}}</CButton>
        <CButton v-if="step == 2" type="submit" color="primary" @click="sendForm" :disabled="loading"><CIcon name="cil-check-circle"/> {{$t('sendEvaluation')}}</CButton>
				<CButton v-else color="success" @click="step = 2" :disabled="loading">{{$t('next')}}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required, email} from '@/helpers/validators'
import moment from 'moment'
import {createEvaluation} from '@/helpers/api/evaluations'

export default {
  name: 'NewEvaluation',
  data () {
    return {
			selectedType: 1,
			step: 1,
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
      haceEntreno: [
				{label: this.$i18n.t('rangeEntrenoA'), value: 1},
				{label: this.$i18n.t('rangeEntrenoB'), value: 2},
				{label: this.$i18n.t('rangeEntrenoC'), value: 3},
				{label: this.$i18n.t('rangeEntrenoD'), value: 4},
				{label: this.$i18n.t('rangeEntrenoE'), value: 5},
				{label: this.$i18n.t('rangeEntrenoF'), value: 6},
			],
      frecEntreno: [
				{label: '2 ' + this.$i18n.t('timesAWeek'), value: 2},
				{label: '3 ' + this.$i18n.t('timesAWeek'), value: 3},
				{label: '4 ' + this.$i18n.t('timesAWeek'), value: 4},
				{label: '5 ' + this.$i18n.t('timesAWeek'), value: 5},
				{label: '6 ' + this.$i18n.t('timesAWeek'), value: 6},
				{label: '7 ' + this.$i18n.t('timesAWeek'), value: 7},
			],
			options: ['Test', 'Test 1'],
			evaluado: null,
      newEvaluation: {
				id_evaluado: null,
				peso: null,
				estatura: null,
				presion_d: null,
				presion_s: null,
				pcintura: null,
				pcadera: null,
				fuma: 'No',
				alcohol: 'No',
				ejercita: 'No',
				hace: 1,
				frecentrena: null,
				triceps: null,
				subescapular: null,
				abdominal: null,
				cuadriceps: null,
				clinical_data: {
					temperatura: '',
					dif_respirar: false,
					ecnt: [
						{name: 'Hipertensión',value: false,tratamiento: false},
						{name: 'Hipercolesteremia',value: false,tratamiento: false},
						{name: 'Diabetes',value: false,tratamiento: false},
						{name: 'Obesidad',value: false,tratamiento: false},
						{name: 'Hígado Graso',value: false,tratamiento: false},
						{value: false,name: '',showInput: true,tratamiento: false},
					],
					autoinmune: [
						{name: 'Lupus',value: false,tratamiento: false},
						{name: 'Celíaca',value: false,tratamiento: false},
						{name: 'Cáncer',value: false,tratamiento: false},
						{name: 'VIH',value: false,tratamiento: false},
						{name: 'Artritis',value: false,tratamiento: false},
						{value: false,name: '',showInput: true,tratamiento: false},
					]
				}
      }
    }
  },
  watch: {
    '$store.state.userData': {
      immediate: true,
      handler (userData) {
        if(userData && !this.$store.state.clientList){
          this.getUsers()
        }
      }
    }
  },
  mounted(){
    this.getUsers()
  },
  methods: {
    required, email,
    getUsers(){
      this.$store.dispatch('getUsersList', {type: 2})
    },
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 12 )
      }
      else{
        date.setFullYear( date.getFullYear() - 6 )
      }
      return date.toISOString().split("T")[0]
    },
    changeShowStatus(status){
      this.resetModal()
    },
    resetModal(){
			this.selectedType = 1
			this.step = 1
			this.evaluado = null
      this.needValidation = false
      this.showRequiredAlert = false
      this.loading = false
      this.newEvaluation = {
				id_evaluado: null,
				peso: null,
				estatura: null,
				presion_d: null,
				presion_s: null,
				pcintura: null,
				pcadera: null,
				fuma: 'No',
				alcohol: 'No',
				ejercita: 'No',
				hace: 1,
				frecentrena: null,
				triceps: null,
				subescapular: null,
				abdominal: null,
				cuadriceps: null,
				clinical_data: {
					temperatura: '',
					dif_respirar: false,
					ecnt: [
						{name: 'Hipertensión',value: false,tratamiento: false},
						{name: 'Hipercolesteremia',value: false,tratamiento: false},
						{name: 'Diabetes',value: false,tratamiento: false},
						{name: 'Obesidad',value: false,tratamiento: false},
						{name: 'Hígado Graso',value: false,tratamiento: false},
						{value: false,name: '',showInput: true,tratamiento: false},
					],
					autoinmune: [
						{name: 'Lupus',value: false,tratamiento: false},
						{name: 'Celíaca',value: false,tratamiento: false},
						{name: 'Cáncer',value: false,tratamiento: false},
						{name: 'VIH',value: false,tratamiento: false},
						{name: 'Artritis',value: false,tratamiento: false},
						{value: false,name: '',showInput: true,tratamiento: false},
					]
				}
      }
			this.$store.commit('setStates', [])
			this.$store.commit('setComunas', [])
    },
		userSelected(user){
			console.log(user)
			this.newEvaluation.id_evaluado = user.id_usuario
			this.newEvaluation.sexo = user.sexo
			this.newEvaluation.ecrono = moment().diff(moment(user.nacimiento, 'YYYY-MM-DD'), 'years')
		},
    isValidForm(){
      this.needValidation = true
      return Object.keys(this.newEvaluation).every((key) => {
				if(key == 'presion_s' || key == 'presion_d'){
					return true
				}
				if(this.selectedType == 1 && (key == 'triceps' || key == 'subescapular' || key == 'abdominal'  || key ==  'cuadriceps')){
					return true
				}
				if(this.selectedType == 3 && (key == 'abdominal'  || key ==  'cuadriceps')){
					return true
				}
				if(key == 'frecentrena'){
					return this.newEvaluation.ejercita == 'No' || !!this.newEvaluation[key]
				}
				if(key == 'clinical_data'){
					return !!this.newEvaluation.clinical_data.temperatura
				}
        return !!this.newEvaluation[key]
      })
    },
    async sendForm(){
      if(this.isValidForm() ){
        let completed = false
        // this.loading = true

				this.newEvaluation.id_evaluador = this.$store.state.userData.id_usuario
				this.newEvaluation.evaluation_type = this.selectedType
				const evaluation = await createEvaluation(this.newEvaluation)
				if(evaluation.message){
					this.$store.dispatch('getEvaluationList')
					this.$store.commit('setNewEvaluationModal', false)
					this.$router.push({ name: 'Evaluation', params: { evaluationID: evaluation.evaluacion.id } })
					this.resetModal()
					completed = true
				}

				this.$store.commit('addToast', {
					color: completed ? 'success':'warning',
					header: completed ? 'Evaluación Creada':'Error',
					message: completed ? 'Se ha creado correctamente la evaluación':this.$i18n.t('errorMessage')
				})
        this.loading = false
      }
      else{
        this.showRequiredAlert = true
      }
    }
  }
}
</script>