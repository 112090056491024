import {endpoints} from './endpoints'
import request from '@/helpers/request'

export function listEvaluations(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.listEvaluations,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getEvaluation(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getEvaluation,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function deleteEvaluation(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteEvaluation,
      method: 'post',
      data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function sendEvaluationByEmail(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendEvaluationByEmail,
      method: 'post',
      data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createEvaluation(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createEvaluation,
      method: 'post',
      data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
