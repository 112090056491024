import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
	'en': {
		spanish: 'Spanish',
		english: 'English',
		portugues: 'Portuguese',
		evaluationTitle: 'Evaluation',
		loadingEvaluation: 'Loading Evaluation',
		evaluatedUserData: 'User Data',
		name: 'Name',
		gender: 'Sex',
		email: 'Email',
		phone: 'Phone',
		region: 'Region',
		comuna: 'City',
		address: 'Address',
		birth: 'Birth Date',
		age: 'Age',
		temperature: 'Temperature',
		dificulToBreath: 'Difficulty breathing',
		cronAge: 'Chronological Age',
		height: 'Height',
		weight: 'Weight',
		alcohol: 'Alcohol',
		smoke: 'Smoke',
		years: 'Years',
		months: 'Months',
		yes: 'Yes',
		no: 'No',
		evaluatedUserClinicalData: 'User Clinical Data',
		entDisease: 'Not transmissible cronic diseases',
		noEntDisease: 'Without not transmissible cronic diseases',
		inTreatment: 'Treathed',
		noTreatment: 'Not treathed',
		autoinmuneDisease: 'Autoimmune diseases',
		noAutoinmuneDisease: 'Without autoimmune diseases',
		entryData: 'Entry Data',
		basicData: 'Basic Data',
		neckPerimeter: 'Neck Perimeter',
		waistPerimeter: 'Waist Perimeter',
		hipPerimeter: 'Hip Perimeter',
		rightArmPerimeter: 'Right Arm Perimeter',
		rightLegPerimeter: 'Right Leg Perimeter',
		diastolic: 'Diastolic Pressure',
		sistolic: 'Sistolic Pressure',
		imc: 'IMC',
		fatPercent: 'Fat Percent',
		magraPercent: 'Lean Mass Percentage',
		imb: 'Basal metabolic rate',
		fisiologicalData: 'Fisiological Data',
		calories: 'calories',
		bodyComposition: 'Body Composition',
		fatWeight: 'Fat Weight',
		magroWeight: 'Lean Weight',
		fullWeight: 'Full Weight',
		graphFat: '% Fat',
		graphLean: '% Lean Mass',
		bodyCompositionAnalysis: 'Body Composition Analysis',
		imcAnalysis: 'IMC Analysis',
		imcIdeal: 'Ideal IMC',
		refImcRange: 'Reference IMC Range',
		veryLow: 'Very Low',
		low: 'Low',
		normal: 'Normal',
		high: 'High',
		veryHigh: 'Very High',
		moderate: 'Moderate',
		optimum: 'Óptimum',
		highModerate: 'Moderate High',
		extremelyHigh: 'Extremely high',
		to: 'To',
		from: 'From',
		waistHeightAnalysis: 'Waist/Height Analysis',
		waistHipAnalysis: 'Waist/Hip Analysis',
		waistHeightIndex: 'Waist/Height Index',
		waistHipIndex: 'Waist/Hip Index',
		referenceRange: 'Reference Range',
		analysis: 'Analysis',
		severeThinness: 'Severe Thinness',
		slightThinness: 'Slight thinness',
		normalWeight: 'Normal Weight',
		overweight: 'Overweight',
		highOverweight: 'High Overweight',
		morbidObesity: 'Morbid Obesity',
		scoring: 'Scoring',
		caloricRequirement: 'Caloric Requirement',
		minimum: 'Minimum',
		average: 'Average',
		maximum: 'Maximum',
		entryLevel: 'Entry Level',
		trainingLevel: 'Training Level',
		risk: 'Risk',
		normal: 'Normal',
		sedentary: 'Sedentary',
		veryGood: 'Very Good',
		basic: 'Basic',
		medium: 'Medium',
		advance: 'Advanced',
		historic: 'History',
		date: 'Date',
		sendEvaluation: 'Send Evaluation',
		sendEvaluationByEmail: 'Send Evaluation By Email',
		enterEmail: 'Enter Email',
		myEvaluations: 'My Evaluations',
		addEvaluation: 'Add Evaluation',
		lastName: 'Last Name',
		creationDate: 'Creation Date',
		actions: 'Actions',
		deletedEvaluation: 'Evaluation Deleted',
		deletedEvaluationMessage: 'The evaluation has been deleted.',
		error: 'Error',
		errorMessage: 'There was an error in the operation, please try again.',
		myClients: 'My Clients',
		addClient: 'Add Client',
		newUser: 'New User',
		enterName: 'Enter Name',
		enterLastname: 'Enter Lastname',
		selectGender: 'Select Gender',
		birthDescription: 'Press the calendar icon to select a date. Age between 6 and 13 years.',
		enterPhone: 'Enter Phone',
		enterAddress: 'Enter Address',
		country: 'Country',
		selectCountry: 'Select Country',
		region: 'Region',
		selectRegion: 'Select Region',
		comuna: 'City',
		selectComuna: 'Select City',
		allFieldsRequired: 'All fields are required',
		createUser: 'Create User',
		createdUser: 'User Created',
		okCreatedUser: 'User has been created',
		errorCreatedUser: 'An error ocurr while creating the user, please try again.',
		editUser: 'Edit User',
		editedUser: 'User Edited',
		okEditUser: 'User data has been modified',
		newNetworkUser: 'New Evaluator User',
		female: 'Female',
		male:	'Male',
		selectJobType: 'Select Job Type',
		contract: 'With Contract',
		independent: 'No Contract',
		company: 'Company',
		enterCompany: 'Enter Company',
		jobTypeA: 'Coach',
		jobTypeB: 'Physical trainer',
		jobTypeC: 'Prof. Physical Education',
		jobTypeD: 'Kinesiologist',
		jobTypeE: 'Nutritionist',
		jobTypeF: 'Plastic Surgeon',
		jobTypeG: 'Bariatrician',
		jobTypeH: 'Massage therapist',
		jobTypeI: 'Massage therapist',
		specialty: 'Specialty',
		selectSpecialty: 'Select Specialty',
		job: 'Job',
		editNetworkUser: 'Edit Network User',
		newEvaluation: 'New Evaluation',
		evaluateData: 'User Data',
		userToEvaluate: 'User to Evaluate',
		selectUserToEvaluate: 'Select User to Evaluate',
		evaluationType: 'Evaluation Type',
		imcMethod: 'Measuring Tape Method',
		twoPMethod: '2 Folds Method',
		fourPMethod: '4 Folds Method',
		enterHeight: 'Enter Height in centimeters',
		enterWeight: 'Enter weight in kilograms',
		enterWaistPerimeter: 'Enter waist perimeter in cm.',
		enterHipPerimeter: 'Enter hip perimeter in cm.',
		enterDiastolic: 'Enter diastolic pressure',
		enterSistolic: 'Enter sistolic pressure',
		pTriceps: 'Triceps fold',
		enterPTriceps: 'Enter triceps fold in mm.',
		pSubescapular: 'Subscapular fold',
		enterPSubescapular: 'Enter subscapular fold in mm.',
		pAbdominal: 'Abdominal fold',
		enterPAbdominal: 'Enter abdominal fold in mm.',
		pCuadriceps: 'Quadriceps fold',
		enterPCuadriceps: 'Enter quadriceps crease in mm.',
		otherDisease: 'Other disease',
		rangeEntrenoA: '1 to 3 weeks',
		rangeEntrenoB: '1 to 3 months',
		rangeEntrenoC: '4 to 6 months',
		rangeEntrenoD: '7 to 11 months',
		rangeEntrenoE: '1 year',
		rangeEntrenoF: 'More than 1 year',
		timesAWeek: 'times a week',
		hasECNTtitle: 'Has any Chronic noncommunicable diseases (CNCD)',
		markIntreatment: 'Check if the user is in treatment',
		hasAutoinmuneTitle: 'Has any Autoimmune disease',
		hasDificulToBreath:'Has difficulty breathing?',
		feverCaution: 'Caution: this value is fever indicator',
		clinicalData: 'Clinical Data',
		trainingFrequencyQuestion: 'How Often?',
		noSinceTrainingQuestion: 'How long have you NOT been exercising?',
		sinceTrainingQuestion: 'How long have you been exercising?',
		doesExercise: 'Does exercise?',
		back: 'Previous',
		next: 'Next',
		fieldRequiredEv: 'These fields are required',
		myNetwork: 'My Network',
		profile: 'Profile',
		logout: 'Log Out',
		creditsQty: 'Available credits',
		loadingInfo: 'Loading Information',
		dashUsuarios: 'Registered Users',
		evaluatedUsers: 'Evaluated Users',
		dashNetworkUsers: 'My Colaborators',
		smoker: 'Does Smoke?',
		drinkAlcohol: 'Drinks Alcohol?',
		optional: 'Optional',
		trainingTime: 'Training Time',
		effortType: 'Effort Type',
		trainingVolumePercent: 'Traning Volume (%)',
		trainingVolumeMinutes: 'Traning Volume (Minutes)',
		minutes: 'Minutes',
		training: 'Training',
		metabolicgastoTraining: 'Metabolic Expenditure Training',
		warmUp: 'Warm Up',
		areobic: 'Aerobics',
		increment: 'Increase',
		minorTo: 'Less than',
		equalTo: 'Equal to',
		mayorTo: 'Bigger than',
		rcaloricoa: 'Sedentary',
		rcaloricob: 'Low Physical Activity',
		rcaloricoc: 'Moderate Physical Activity',
		rcaloricod: 'High Physical Activity',
		moveCreditsTitle: 'Modify user credits',
		credits: 'Credits',
		enterCredits: 'Entrer Credits',
		modify: 'Modify',
		changeCreditsDescription: 'If the amount of credits you assign to the user is greater than the one they currently have, credits will be deducted from your account to add them. If, on the other hand, the amount of credits assigned is less than the current one, the difference will be added to your credits.',
		addNetworkUser: 'Add Network User',
		'Hipertensión': 'Hypertension',
		'Hipercolesteremia': 'Hypercholesterolemia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesity',
		'Hígado Graso': 'Fatty Liver',
		'Lupus': 'Lupus',
		'Celíaca': 'Celiac',
		'Cáncer': 'Cancer',
		'VIH': 'HIV',
		'Artritis': 'Rheumatoid arthritis',
	},
	'es': {
		spanish: 'Español',
		english: 'Inglés',
		portugues: 'Portugués',
		evaluationTitle: 'Evaluación',
		loadingEvaluation: 'Cargando Evaluación',
		evaluatedUserData: 'Datos del Evaluado',
		name: 'Nombre',
		gender: 'Sexo',
		email: 'Email',
		phone: 'Teléfono',
		region: 'Región',
		comuna: 'Comuna',
		address: 'Dirección',
		birth: 'Fecha Nacimiento',
		age: 'Edad',
		temperature: 'Temperatura',
		dificulToBreath: 'Dificultad para respirar',
		cronAge: 'Edad Crónologica',
		height: 'Estatura',
		weight: 'Peso',
		alcohol: 'Alcohol',
		smoke: 'Fuma',
		years: 'Años',
		months: 'Meses',
		yes: 'Si',
		no: 'No',
		evaluatedUserClinicalData: 'Datos Clínicos del Evaluado',
		entDisease: 'Enfermedades E.N.T',
		noEntDisease: 'El usuario no declara enfermedades ECNT',
		inTreatment: 'En tratamiento',
		noTreatment: 'Sin tratamiento',
		autoinmuneDisease: 'Enfermedades Autoinmunes',
		noAutoinmuneDisease: 'El usuario no declara enfermedades Autoinmunes',
		entryData: 'Datos de Entrada',
		basicData: 'Datos Básicos',
		neckPerimeter: 'Perímetro Cuello',
		waistPerimeter: 'Perímetro Cintura',
		hipPerimeter: 'Perímetro Cadera',
		rightArmPerimeter: 'Perímetro Brazo Derecho',
		rightLegPerimeter: 'Perímetro Pierna Derecha',
		diastolic: 'Presión Diastólica',
		sistolic: 'Presión Sistólica',
		imc: 'IMC',
		fatPercent: 'Porcentaje de Grasa',
		magraPercent: 'Porcentaje Masa Magra',
		imb: 'Indice metabólico Basal',
		fisiologicalData: 'Datos Fisiológicos',
		calories: 'calorías',
		bodyComposition: 'Composición Corporal',
		fatWeight: 'Peso Graso',
		magroWeight: 'Peso Magro',
		fullWeight: 'Peso Total',
		graphFat: '% Grasa',
		graphLean: '% Masa Magra',
		bodyCompositionAnalysis: 'Análisis Composición Corporal',
		imcAnalysis: 'Análisis IMC',
		imcIdeal: 'IMC Ideal',
		refImcRange: 'Rangos IMC de Referencia',
		veryLow: 'Muy Bajo',
		low: 'Bajo',
		normal: 'Normal',
		high: 'Alto',
		veryHigh: 'Muy Alto',
		moderate: 'Moderado',
		optimum: 'Óptimo',
		highModerate: 'Moderado Alto',
		extremelyHigh: 'Extremadamente Alto',
		to: 'Hasta',
		from: 'Desde',
		waistHeightAnalysis: 'Análisis Cintura/Estatura',
		waistHipAnalysis: 'Análisis Cintura/Cadera',
		waistHeightIndex: 'Índice Cintura/Estatura',
		waistHipIndex: 'Índice Cintura/Cadera',
		referenceRange: 'Rangos de Referencia',
		analysis: 'Análisis',
		severeThinness: 'Delgadez Severa',
		slightThinness: 'Delgadez Leve',
		normalWeight: 'Peso Normal ',
		overweight: 'Sobrepeso',
		highOverweight: 'Sobrepeso Elevado',
		morbidObesity: 'Obesidad Mórbida',
		scoring: 'Scoring',
		caloricRequirement: 'Requermiento Cálorico',
		minimum: 'Mínimo',
		average: 'Promedio',
		maximum: 'Máximo',
		entryLevel: 'Nivel de Entrada',
		trainingLevel: 'Nivel de Entrenamiento',
		risk: 'Riesgo',
		normal: 'Normal',
		sedentary: 'Sedentario',
		veryGood: 'Muy Bueno',
		basic: 'Básico',
		medium: 'Intermedio',
		advance: 'Avanzado',
		historic: 'Historial',
		date: 'Fecha',
		sendEvaluation: 'Enviar Evaluación',
		sendEvaluationByEmail: 'Enviar Evaluación por Email',
		enterEmail: 'Ingresa el email',
		myEvaluations: 'Mis Evaluaciones',
		addEvaluation: 'Agregar Evaluación',
		lastName: 'Apellido',
		creationDate: 'Fecha Creación',
		actions: 'Acciones',
		deletedEvaluation: 'Evaluación Eliminada',
		deletedEvaluationMessage: 'Se ha eliminado la evaluación.',
		error: 'Error',
		errorMessage: 'Hubo un error en la operación, por favor intenta nuevamente.',
		myClients: 'Mis Clientes',
		addClient: 'Agregar Cliente',
		newUser: 'Nuevo Usuario',
		enterName: 'Ingresa Nombre',
		enterLastname: 'Ingresa Apellido',
		selectGender: 'Selecciona Sexo',
		birthDescription: 'Presiona el icono de calendario para seleccionar fecha. Edad entre 6 y 13 años.',
		enterPhone: 'Ingresar Teléfono',
		enterAddress: 'Ingresar Dirección',
		country: 'País',
		selectCountry: 'Selecciona País',
		region: 'Región',
		selectRegion: 'Selecciona Región',
		comuna: 'Ciudad',
		selectComuna: 'Selecciona Ciudad',
		allFieldsRequired: 'Todos los campos son obligatorios',
		createUser: 'Crear Usuario',
		createdUser: 'Usuario Creado',
		okCreatedUser: 'Se ha creado correctamente el usuario',
		errorCreatedUser: 'Hubo un error en la creación del usuario, por favor intenta nuevamente.',
		editUser: 'Editar Usuario',
		editedUser: 'Usuario Modificado',
		okEditUser: 'Se han modificado los datos del usuario',
		newNetworkUser: 'Nuevo Usuario Evaluador',
		female: 'Femenino',
		male:	'Masculino',
		selectJobType: 'Seleccione tipo trabajo',
		contract: 'Dependiente',
		independent: 'Independiente',
		company: 'Empresa',
		enterCompany: 'Ingresa Empresa',
		jobTypeA: 'Entrenador',
		jobTypeB: 'Preparador Físico',
		jobTypeC: 'Prof. Educ. Física',
		jobTypeD: 'Kinesiólogo',
		jobTypeE: 'Nutricionista',
		jobTypeF: 'Cirujano Plástico',
		jobTypeG: 'Bariatra',
		jobTypeH: 'Masoterapeuta',
		jobTypeI: 'Masajista',
		specialty: 'Especialidad',
		selectSpecialty: 'Seleciona Especialidad',
		job: 'Trabajo',
		editNetworkUser: 'Editar Usuario Evaluador',
		newEvaluation: 'Nueva Evaluación',
		evaluateData: 'Datos Evaluado',
		userToEvaluate: 'Usuario a evaluar',
		selectUserToEvaluate: 'Seleccione Evaluado',
		evaluationType: 'Forma de Evaluación',
		imcMethod:'Método Cinta Métrica',
		twoPMethod:'Método 2 Pliegues',
		fourPMethod:'Método 4 Pliegues',
		enterHeight: 'Ingrese estatura en centímetros',
		enterWeight: 'Ingrese peso en kilogramos',
		enterWaistPerimeter: 'Ingrese perimetro cintura en cm.',
		enterHipPerimeter: 'Ingrese perimetro cadera en cm.',
		enterDiastolic: 'Ingrese presión diastólica',
		enterSistolic: 'Ingrese presión sistólica',
		pTriceps: 'Pliegue Triceps',
		enterPTriceps: 'Ingrese pliegue triceps en mm.',
		pSubescapular: 'Pliegue Subescapular',
		enterPSubescapular: 'Ingrese pliegue subescapular en mm.',
		pAbdominal: 'Pliegue Abdominal',
		enterPAbdominal: 'Ingrese pliegue abdominal en mm.',
		pCuadriceps: 'Pliegue Cuadriceps',
		enterPCuadriceps: 'Ingrese pliegue cuadriceps en mm.',
		otherDisease: 'Otra enfermedad',
		rangeEntrenoA: '1 to 3 semanas',
		rangeEntrenoB: '1 a 3 meses',
		rangeEntrenoC: '4 a 6 meses',
		rangeEntrenoD: '7 a 11 meses',
		rangeEntrenoE: '1 año',
		rangeEntrenoF: 'Más de 1 año',
		timesAWeek: 'veces a la semana',
		hasECNTtitle: 'Presenta alguna de estas Enfermedades Crónicas No Transmisibles (ECNT)',
		markIntreatment: 'Marque si está en tratamiento',
		hasAutoinmuneTitle: 'Presenta alguna enfermedad Autoinmune',
		hasDificulToBreath:'¿Presenta dificultad para respirar?',
		feverCaution: 'Precaución: El valor ingresado indica fiebre',
		clinicalData: 'Datos Clínicos',
		trainingFrequencyQuestion: '¿Con que frecuencia?',
		noSinceTrainingQuestion: '¿Hace cuanto tiempo NO hace ejercicio físico?',
		sinceTrainingQuestion: '¿Hace cuanto tiempo hace ejercicio físico?',
		doesExercise: '¿Se ejercita?',
		fieldRequiredEv: 'Los campos marcados son obligatorios',
		myNetwork: 'Mis Colaboradores',
		profile: 'Perfil',
		logout: 'Salir',
		creditsQty: 'Cantidad de créditos disponibles',
		loadingInfo: 'Cargando Información',
		dashUsuarios: 'Usuarios Registrados',
		evaluatedUsers: 'Usuarios Evaluados',
		dashNetworkUsers: 'Mis Colaboradores',
		smoker: '¿Fumador?',
		drinkAlcohol: '¿Bebe Alcohol?',
		optional: 'Opcional',
		trainingTime: 'Tiempo Entrenamiento',
		effortType: 'Tipo de Esfuerzo',
		trainingVolumePercent: 'Volumen Entrenamiento (%)',
		trainingVolumeMinutes: 'Volumen Entrenamiento (Minutos)',
		minutes: 'Minutos',
		training: 'Entrenamiento',
		metabolicgastoTraining: 'Gasto Metabólico Entrenamiento',
		warmUp: 'Calentamiento',
		areobic: 'Aeróbica',
		increment: 'Incremento',
		minorTo: 'Menor a',
		equalTo: 'Igual a',
		mayorTo: 'Mayor a',
		rcaloricoa: 'Sedentario',
		rcaloricob: 'Actividad Física Baja ',
		rcaloricoc: 'Actividad Física Moderada',
		rcaloricod: 'Actividad Física Alta',
		moveCreditsTitle: 'Modificar créditos de este usuario',
		credits: 'Créditos',
		enterCredits: 'Ingrese créditos',
		modify: 'Modificar',
		back: 'Anterior',
		next: 'Siguiente',
		changeCreditsDescription: 'Si la cantidad de créditos que asignas al usuario es mayor a la que tiene actualmente se descontarán créditos de tu cuenta para agregarselos. Si por el contrario, la cantidad de créditos asignada es menor a la actual se sumará la diferencia a tus créditos.',
		addNetworkUser: 'Agregar Usuario Evaluador',
		'Hipertensión': 'Hipertensión',
		'Hipercolesteremia': 'Hipercolesteremia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesidad',
		'Hígado Graso': 'Hígado Graso',
		'Lupus': 'Lupus',
		'Celíaca': 'Celíaca',
		'Cáncer': 'Cáncer',
		'VIH': 'VIH',
		'Artritis': 'Artritis',
	},
	'pt': {
		spanish: 'Espanhol',
		english: 'Inglês',
		portugues: 'Português',
		evaluationTitle: 'Avaliação',
		loadingEvaluation: 'Avaliação de carregamento',
		evaluatedUserData: 'Dados dos Avaliados',
		name: 'Nome',
		gender: 'Sexo',
		email: 'O email',
		phone: 'Telefone',
		region: 'Região',
		comuna: 'Comuna',
		address: 'Endereço',
		birth: 'Data de nascimento',
		age: 'Era',
		temperature: 'Temperatura',
		dificulToBreath: 'Dificuldade para respirar',
		cronAge: 'Idade cronologica',
		height: 'Altura',
		weight: 'Peso',
		alcohol: 'Álcool',
		smoke: 'Fuma',
		years: 'Anos',
		months: 'Meses',
		yes: 'sim',
		no: 'Não',
		evaluatedUserClinicalData: 'Dados clínicos dos avaliados',
		entDisease: 'Doenças E.N.T',
		noEntDisease: 'O usuário não declara doenças DCNT',
		inTreatment: 'Em tratamento',
		noTreatment: 'Sem tratamento',
		autoinmuneDisease: 'Doenças autoimunes',
		noAutoinmuneDisease: 'O usuário não declara doenças autoimunes',
		entryData: 'Dados de entrada',
		basicData: 'Dados básicos',
		neckPerimeter: 'Perímetro do pescoço',
		waistPerimeter: 'Perímetro da cintura',
		hipPerimeter: 'Circunferência do quadril',
		rightArmPerimeter: 'Perímetro do braço direito',
		rightLegPerimeter: 'Perímetro da perna direita',
		diastolic: 'Pressão diastólica',
		sistolic: 'Pressão sistólica',
		imc: 'IMC',
		fatPercent: 'Média de gordura',
		magraPercent: 'Porcentagem de massa magra',
		imb: 'Taxa metabólica Basal',
		fisiologicalData: 'Dados Fisiológicos',
		calories: 'calorias',
		bodyComposition: 'Composição do corpo',
		fatWeight: 'Peso gordo',
		magroWeight: 'Peso Magro',
		fullWeight: 'Peso total',
		graphFat: '% Gordura',
		graphLean: '% Massa Enxuta',
		bodyCompositionAnalysis: 'Análise de composição corporal',
		imcAnalysis: 'Análise de IMC',
		imcIdeal: 'IMC ideal',
		refImcRange: 'Faixas de IMC de referência',
		veryLow: 'Muito baixo',
		low: 'Baixo',
		normal: 'Normal',
		high: 'Alto',
		veryHigh: 'Muito alto',
		moderate: 'Moderado',
		optimum: 'Ótimo',
		highModerate: 'Moderado alto',
		extremelyHigh: 'Extremamente alto',
		to: 'Até',
		from: 'A partir de',
		waistHeightAnalysis: 'Análise de cintura / altura',
		waistHipAnalysis: 'Análise de cintura / quadril',
		waistHeightIndex: 'Índice de cintura / altura',
		waistHipIndex: 'Relação cintura quadril',
		referenceRange: 'Faixas de Referência',
		analysis: 'Análise',
		severeThinness: 'Magreza severa',
		slightThinness: 'Ligeira magreza',
		normalWeight: 'Peso normal',
		overweight: 'Excesso de peso',
		highOverweight: 'Sobrepeso alto',
		morbidObesity: 'Obesidade mórbida',
		scoring: 'Pontuação',
		caloricRequirement: 'Necessidade Calórica',
		minimum: 'Mínimo',
		average: 'Média',
		maximum: 'Máximo',
		entryLevel: 'Nível de entrada',
		trainingLevel: 'Nível de treinamento',
		risk: 'Risco',
		normal: 'Normal',
		sedentary: 'Sedentário',
		veryGood: 'Muito bom',
		basic: 'Básico',
		medium: 'Intermediário',
		advance: 'Avançado',
		historic: 'Registro',
		date: 'Encontro',
		sendEvaluation: 'Enviar Avaliação',
		sendEvaluationByEmail: 'Enviar avaliação por e-mail',
		enterEmail: 'Digite o e-mail',
		myEvaluations: 'Minhas Avaliações',
		addEvaluation: 'Adicionar Avaliação',
		lastName: 'Sobrenome',
		creationDate: 'Data de criação',
		actions: 'Ações',
		deletedEvaluation: 'Avaliação Removida',
		deletedEvaluationMessage: 'A avaliação foi removida.',
		error: 'Erro',
		errorMessage: 'Ocorreu um erro na operação, tente novamente.',
		myClients: 'Meus clientes',
		addClient: 'Adicionar Cliente',
		newUser: 'Novo usuário',
		enterName: 'Insira o nome',
		enterLastname: 'Digite o sobrenome',
		selectGender: 'Selecione o sexo',
		birthDescription: 'Pressione o ícone do calendário para selecionar a data. Idade entre 6 e 13 anos.',
		enterPhone: 'Digite o telefone',
		enterAddress: 'Insira o endereço',
		country: 'País',
		selectCountry: 'Selecione o pais',
		region: 'Região',
		selectRegion: 'Selecione a região',
		comuna: 'Cidade',
		selectComuna: 'Selecione a cidade',
		allFieldsRequired: 'Todos os campos são obrigatórios',
		createUser: 'Criar usuário',
		createdUser: 'Criado pelo usuário',
		okCreatedUser: 'O usuário foi criado com sucesso',
		errorCreatedUser: 'Ocorreu um erro ao criar o usuário, tente novamente.',
		editUser: 'Editar usuário',
		editedUser: 'Usuário Modificado',
		okEditUser: 'Os dados do usuário foram modificados',
		newNetworkUser: 'Novo usuário avaliador',
		female: 'Feminino',
		male: 'Masculino',
		selectJobType: 'Selecione o tipo de trabalho',
		contract: 'Dependente',
		independent: 'Independente',
		company: 'Empresa',
		enterCompany: 'Entrar na empresa',
		jobTypeA: 'Treinador',
		jobTypeB: 'Preparador físico',
		jobTypeC: 'Prof. Educ. Física',
		jobTypeD: 'Cinesiologista',
		jobTypeE: 'Nutricionista',
		jobTypeF: 'Cirurgião plástico',
		jobTypeG: 'Bariátrico',
		jobTypeH: 'Massoterapeuta',
		jobTypeI: 'Massoterapeuta',
		specialty: 'Especialidade',
		selectSpecialty: 'Selecione a especialidade',
		job: 'Trabalho',
		editNetworkUser: 'Editar avaliador de usuário',
		newEvaluation: 'Nova Avaliação',
		evaluateData: 'Dados avaliados',
		userToEvaluate: 'Usuário para avaliar',
		selectUserToEvaluate: 'Selecione Avaliada',
		evaluationType: 'Formulário de avaliação',
		imcMethod: 'Método de Fita Métrica',
		twoPMethod: 'Método 2 dobras',
		fourPMethod: 'Método 4 dobras',
		enterHeight: 'Insira a altura em centímetros',
		enterWeight: 'Insira o peso em quilogramas',
		enterWaistPerimeter: 'Insira a circunferência da cintura em cm.',
		enterHipPerimeter: 'Insira a circunferência do quadril em cm.',
		enterDiastolic: 'Insira a pressão diastólica',
		enterSistolic: 'Insira a pressão sistólica',
		pTriceps: 'Dobra de tríceps',
		enterPTriceps: 'Insira a dobra do tríceps em mm.',
		pSubescapular: 'Dobra subescapular',
		enterPSubescapular: 'Insira a dobra subescapular em mm.',
		pAbdominal: 'Prega abdominal',
		enterPAbdominal: 'Entre na prega abdominal em mm.',
		pCuadriceps: 'Dobra de quadríceps',
		enterPCuadriceps: 'Insira a prega do quadríceps em mm.',
		otherDisease: 'Outra doença',
		rangeEntrenoA: '1 a 3 semanas',
		rangeEntrenoB: '1 a 3 meses',
		rangeEntrenoC: '4 a 6 meses',
		rangeEntrenoD: '7 a 11 meses',
		rangeEntrenoE: '1 ano',
		rangeEntrenoF: 'Mais de 1 ano',
		timesAWeek: 'vezes por semana',
		hasECNTtitle: 'Apresenta qualquer uma dessas doenças crônicas não transmissíveis (DCNT)',
		markIntreatment: 'Verifique se você está em tratamento',
		hasAutoinmuneTitle: 'Tem alguma doença autoimune',
		hasDificulToBreath: 'Você tem dificuldade para respirar?',
		feverCaution: 'O valor inserido indica febre',
		clinicalData: 'Dados clínicos',
		trainingFrequencyQuestion: 'Com que frequência?',
		noSinceTrainingQuestion: 'Há quanto tempo você NÃO faz exercícios?',
		sinceTrainingQuestion: 'Há quanto tempo você faz exercícios?',
		doesExercise: 'Ele malha?',
		fieldRequiredEv: 'Os campos marcados são obrigatórios',
		myNetwork: 'Minha rede',
		profile: 'Perfil',
		logout: 'Saia',
		creditsQty: 'Quantidade de créditos disponíveis',
		loadingInfo: 'Carregando informações',
		dashUsuarios: 'Usuários registrados',
		evaluatedUsers: 'Usuários avaliados',
		dashNetworkUsers: 'Meus colaboradores',
		smoker: '¿Fuma?',
		drinkAlcohol: '¿Bebe álcool?',
		optional: 'Opcional',
		trainingTime: 'Tempo de treino',
		effortType: 'Tipo de Esforço',
		trainingVolumePercent: 'Volume de treinamento (%)',
		trainingVolumeMinutes: 'Volume de treinamento (minutos)',
		minutes: 'Minutos',
		training: 'Treinamento',
		metabolicgastoTraining: 'Treinamento de despesas metabólicas',
		warmUp: 'Aquecimento',
		areobic: 'Aeróbica',
		increment: 'Aumentar',
		minorTo: 'Menor que',
		equalTo: 'Igual a',
		mayorTo: 'Maior que',
		rcaloricoa: 'Sedentário',
		rcaloricob: 'Baixa Atividade Física',
		rcaloricoc: 'Atividade física moderada',
		rcaloricod: 'Alta Atividade Física',
		moveCreditsTitle: 'Modificar os créditos deste usuário',
		credits: 'Créditos',
		enterCredits: 'Digite os créditos',
		modify: 'Modificar',
		back: 'Anterior',
		next: 'Próximo',
		changeCreditsDescription: 'Se a quantidade de créditos que você atribui ao usuário for maior do que a que ele possui atualmente, os créditos serão deduzidos de sua conta para adicioná-los. Se, por outro lado, o montante de créditos atribuídos for inferior ao atual, a diferença será adicionada aos seus créditos.',
		addNetworkUser: 'Adicionar usuário de rede',
		'Hipertensión': 'Hipertensão',
		'Hipercolesteremia': 'Hipercolesterolemia',
		'Diabetes': 'Diabetes',
		'Obesidad': 'Obesidade',
		'Hígado Graso': 'Fígado gordo',
		'Lupus': 'Lúpus',
		'Celíaca': 'Celíaca',
		'Cáncer': 'Câncer',
		'VIH': 'HIV',
		'Artritis': 'Artrite',
	}
};

const locale = localStorage.getItem('lang')

export const i18n = new VueI18n({
	locale,
	fallbackLocale: 'es',
	messages,
});