<template>
  <div>
    <CModal
      :title="$t('newUser')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
      :show.sync="$store.state.newUserModal"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('newUser')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setNewUserModal', false)
          resetModal()
        }" class="text-white"/>
      </template>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol sm="12" md="6">
                <CInput
                  v-model="newUser.nombre"
                  :label="$t('name')"
                  :placeholder="$t('enterName')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="newUser.apellido"
                  :label="$t('lastName')"
                  :placeholder="$t('enterLastname')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CSelect
                  :value.sync="newUser.sexo"
                  :label="$t('gender')"
                  horizontal
                  :options="[{label: $t('female'), value: 'Femenino'}, {label: $t('male'), value: 'Masculino'}]"
									:reduce="data => data.value"
                  :placeholder="$t('selectGender')"
                />
                <CInput
                  v-model="newUser.nacimiento"
                  :label="$t('birth')"
                  type="date"
                  :min="setDate(1)"
                  :max="setDate(2)"
                  :description="$t('birthDescription')"
                  horizontal
                  :isValid="needValidation ? required && minDate:undefined"
                />
                <CInput
                  v-model="newUser.email"
                  :label="$t('email')"
                  :placeholder="$t('enterEmail')"
                  type="email"
                  horizontal
                  :isValid="needValidation ? required && email:undefined"
                />
              </CCol>
              <CCol>
                <CInput
                  v-model="newUser.telefono"
                  :label="$t('phone')"
                  :placeholder="$t('enterPhone')"
                  horizontal
                  :prepend="phonecode"
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="newUser.direccion"
                  :label="$t('address')"
                  :placeholder="$t('enterAddress')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('country')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectCountry')"
                      :options="$store.state.countries"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(country) => {
                        phonecode = '+' + country.phonecode
                        getStates(country.id)
                        newUser.region = null
                        newUser.comuna = null
                      }"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.pais"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('region')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectRegion')"
                      :options="$store.state.states"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(state) => {
                        getComunas(state.id)
                        newUser.comuna = null
                      }"
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.region"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('comuna')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectComuna')"
                      :options="$store.state.comunas"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      taggable
                      :createOption="option => ({name:option})"
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.comuna"></v-select>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <div slot="footer" class="d-flex align-items-center justify-end">
        <CBadge v-if="showRequiredAlert" color="warning" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> {{$t('allFieldsRequired')}}</CBadge>
        <CButton type="submit" color="primary" @click="sendForm" :disabled="loading"><CIcon name="cil-check-circle"/> {{$t('createUser')}}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required, email, minDate} from '@/helpers/validators'
import {getCountries, getStates, getComunas} from '@/helpers/api/regionalization.js'
import {createUser, createUserProfile} from '@/helpers/api/users.js'

export default {
  name: 'NewUserModal',
  data () {
    return {
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
      phonecode: '',
      newUser: {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
      }
    }
  },
  mounted(){
    this.getCountries()
  },
  methods: {
    required, email, minDate,
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 13 )
      }
      else{
        date.setFullYear( date.getFullYear() - 6 )
      }
      return date.toISOString().split("T")[0]
    },
    changeShowStatus(status){
      this.resetModal()
    },
    resetModal(){
      this.needValidation = false
      this.showRequiredAlert = false
      this.loading = false
      this.newUser = {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
      }
    },
    async getCountries(){
      const countries = await getCountries()
      if(countries.countries){
        this.$store.commit('setCountries', countries.countries)
      }
    },
    async getStates(country_id){
      const states = await getStates({country_id})
      if(states.states){
        this.$store.commit('setStates', states.states)
      }
    },
    async getComunas(state_id){
      const comunas = await getComunas({state_id})
      if(comunas.cities){
        this.$store.commit('setComunas', comunas.cities)
      }
    },
    isValidForm(){
      this.needValidation = true
      return Object.keys(this.newUser).every((key) => {
        if(key == 'nacimiento'){
          return this.minDate(this.newUser[key])
        }
        return !!this.newUser[key]
      })
    },
    async sendForm(){
      if(this.isValidForm()){
        this.loading = true
        try{
          let completed = false
          const userData = {
            email: this.newUser.email,
            lang: this.$i18n.locale,
            parent_user_id: this.$store.state.userData.id_usuario,
            type: 2,
          }
          const user = await createUser(userData)
          if(user.data){
            let userProfileData = {...this.newUser}
            userProfileData.telefono = this.phonecode + userProfileData.telefono
            userProfileData.id_usuario = user.data.id
            const userProfile = await createUserProfile(userProfileData)
            if(userProfile.data){
              completed = true
              this.$store.dispatch('getUsersList', {type: 2})
              this.$store.commit('setNewUserModal', false)
              this.resetModal()
            }
          }

          this.$store.commit('addToast', {
            color: completed ? 'success':'warning',
            header: completed ? this.$i18n.t('createdUser'):'Error',
            message: completed ? this.$i18n.t('okCreatedUser'):this.$i18n.t('errorCreatedUser')
          })
        }
        catch(e){
          console.log(e)

          this.$store.commit('addToast', {
            color: 'warning',
            header: 'Error',
            message: e.response.data.error.message.email ? e.response.data.error.message.email[0]:this.$i18n.t('errorMessage')
          })
        }
        this.loading = false
      }
      else{
        this.showRequiredAlert = true
      }
    }
  }
}
</script>