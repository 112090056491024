<template>
  <div>
    <router-view></router-view>
    <ModalIndex />
    <CToaster :autohide="5000">
      <template v-for="(toast, index) in $store.state.fixedToasts">
        <CToast
          :key="index"
          :show="true"
          :color="toast.color"
          :header="toast.header"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import ModalIndex from '@/views/modals/Index'
import { getUser, getAuth } from '@/helpers/api/user'

export default {
  name: 'App',
  components: {ModalIndex},
  mounted(){
    if(this.$store.state.isAuthenticated){
      this.getUserInfo()
    }
  },
  methods: {
    async getUserInfo(){
      const user = getAuth()
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
