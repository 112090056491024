<template>
  <div>
    <CModal
      :title="$t('editUser')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
      :show.sync="$store.state.editUserModal.status"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('editUser')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setEditUserModal', {status: false, userData: null})
          resetModal()
        }" class="text-white"/>
      </template>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol sm="12" md="6">
                <CInput
                  v-model="editUser.nombre"
                  :label="$t('name')"
                  :placeholder="$t('enterName')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.apellido"
                  :label="$t('lastName')"
                  :placeholder="$t('enterLastname')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CSelect
                  :value.sync="editUser.sexo"
                  :label="$t('gender')"
                  horizontal
                  :options="[{label: $t('female'), value: 'Femenino'}, {label: $t('male'), value: 'Masculino'}]"
									:reduce="data => data.value"
                  :placeholder="$t('selectGender')"
                />
                <CInput
                  v-model="editUser.nacimiento"
                  :label="$t('birth')"
                  type="date"
                  :min="setDate(1)"
                  :max="setDate(2)"
                  :description="$t('birthDescription')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.email"
                  :label="$t('email')"
                  :placeholder="$t('enterEmail')"
                  type="email"
                  horizontal
                  :isValid="needValidation ? required && email:undefined"
                />
              </CCol>
              <CCol>
                <CInput
                  v-model="editUser.telefono"
                  :label="$t('phone')"
                  :placeholder="$t('enterPhone')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.direccion"
                  :label="$t('address')"
                  :placeholder="$t('enterAddress')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('country')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectCountry')"
                      :options="$store.state.countries"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(country) => {
                        getStates(country.id)
                        editUser.region = null
                        editUser.comuna = null
                      }"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.pais"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('region')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectRegion')"
                      :options="$store.state.states"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(state) => {
                        getComunas(state.id)
                        editUser.comuna = null
                      }"
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.region"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('comuna')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectComuna')"
                      :options="$store.state.comunas"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.comuna"></v-select>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <div slot="footer" class="d-flex align-items-center justify-end">
        <CBadge v-if="showRequiredAlert" color="warning" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> {{$t('allFieldsRequired')}}</CBadge>
        <CButton type="submit" color="primary" @click="sendForm" :disabled="loading"><CIcon name="cil-check-circle" :disabled="loading"/> {{$t('editUser')}}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required, email} from '@/helpers/validators'
import {getCountries, getStates, getComunas} from '@/helpers/api/regionalization.js'
import {editUser} from '@/helpers/api/users.js'

export default {
  name: 'EditUserModal',
  data () {
    return {
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
      editUser: {
				id_usuario: null,
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
      }
    }
  },
  watch: {
    '$store.state.editUserModal': {
      immediate: true,
      handler (editUserModal) {
        if(editUserModal && editUserModal.status && editUserModal.userData){
          this.getUserToEdit()
        }
      }
    }
  },
  mounted(){
    this.getCountries()
  },
  methods: {
    required, email,
		async getUserToEdit(){
			Object.keys(this.editUser).forEach(key => {
				this.editUser[key] = this.$store.state.editUserModal.userData[key]
			})
		},
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 12 )
      }
      else{
        date.setFullYear( date.getFullYear() - 6 )
      }
      return date.toISOString().split("T")[0]
    },
    changeShowStatus(status){
      this.resetModal()
    },
    resetModal(){
      this.needValidation = false
      this.showRequiredAlert = false
      this.editUser = {
				id_usuario: null,
        loading: false,
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
      }
			this.$store.commit('setStates', [])
			this.$store.commit('setComunas', [])
    },
    async getCountries(){
      const countries = await getCountries()
      if(countries.countries){
        this.$store.commit('setCountries', countries.countries)
      }
    },
    async getStates(country_id){
      const states = await getStates({country_id})
      if(states.states){
        this.$store.commit('setStates', states.states)
      }
    },
    async getComunas(state_id){
      const comunas = await getComunas({state_id})
      if(comunas.cities){
        this.$store.commit('setComunas', comunas.cities)
      }
    },
    isValidForm(){
      this.needValidation = true
      return Object.keys(this.editUser).every((key) => {return !!this.editUser[key]})
    },
    async sendForm(){
      if(this.isValidForm()){
        this.loading = true
        let completed = false
        const user = await editUser(this.editUser)
        if(user.data){
          this.$store.dispatch('getUsersList', {type: 2})
					this.$store.commit('setEditUserModal', {status: false, userData: null})
					this.resetModal()
					completed = true
        }

        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
          header: completed ? this.$i18n.t('editedUser:'):'Error',
          message: completed ? this.$i18n.t('okEditUser'):this.$i18n.t('errorMessage')
        })
        this.loading = false
      }
      else{
        this.showRequiredAlert = true
      }
    }
  }
}
</script>