const endpoints = {
    login: '/web/authenticate',
    authUser: '/web/authenticate/user',
    user: '/web/datosusuario',
    getUsers: '/web/listadoperfil',
    getCountries: '/web/countries',
    getStates: '/web/states',
    getComunas: '/web/cities',
    createUser: '/web/creacuenta',
    createUserProfile: '/web/ingresoperfil',
    editUser: '/web/editaperfil',
    deleteUser: '/web/eliminausuario',
    listEvaluations: '/web/listaevaluaciones',
    getEvaluation: '/web/getevaluacion',
		deleteEvaluation: '/web/eliminaevaluacion',
    sendEvaluationByEmail: '/web/enviarevalua',
    createEvaluation: '/web/ingresaevaluacion',
		dashboard: '/web/getdash',
		modifyCredits: '/web/cambiacreditosnew'
}

export {endpoints}