import {endpoints} from './endpoints'
import request from '@/helpers/request'

export function getCountries(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getCountries,
      method: 'get'
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getStates(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getStates,
      method: 'get',
			params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function getComunas(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getComunas,
      method: 'get',
			params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}